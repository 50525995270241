import React from "react"
import Layout from "../components/layout"
import anne from "../media/profil.svg"
import { Box, Image, Text } from "@chakra-ui/react"

export default function Home({ data }) {
  return (
    <Layout title="Hatha &amp; Restorative Yoga" slug="" showTitle={false}>
      <Image
        mt={12}
        mb={-16}
        src={anne}
        alt="Illustration von der Yogahaltung Dhanurasana"
      />
      <Box textAlign="center">
        <Text fontSize="4xl" as="h1" fontFamily="Philosopher">
          Anne Rieck
        </Text>
        <Box mb={12}>
          <Text fontSize="md" as="i">
            -Hatha & Restorative Yoga-
          </Text>
        </Box>
        <Text fontSize="md">
          Wie schön, dass du da bist.
          <br />
          <br />
          Herzlich, klar und verständlich verbinde ich mit Yoga
          <br />
          Hand mit Fuß, aber auch Herz mit Verstand,
          <br />
          Natur mit Mensch und
          <br />
          Intuition mit Vernunft.
        </Text>
      </Box>
    </Layout>
  )
}
